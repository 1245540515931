<template>
	<span>
		{{value}}
	</span>
</template>



<script>
export default {
	name: "StringCell",
	props: ["item", "column"],
	computed: {
		value(){
			return this.item[this.column.value]
		}
	}
};
</script>
